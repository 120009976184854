import React from 'react';
import "@weavy/uikit-react/dist/css/weavy.css";

const App = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-wheel"></div>
        <div className="loader-text"></div>
      </div>
    </div>
  )
}

export default App;
import React, { useCallback, useEffect, useState } from 'react';
import { WeavyClient, WeavyProvider, MessengerProvider, ConversationList, Conversation } from "@weavy/uikit-react";
import "@weavy/uikit-react/dist/css/weavy.css";
import "./App.css"
import queryString from "query-string";
import axios from 'axios';
import get from "lodash.get"
import Loading from "./Loading"

const WEAVY_ENV_URL = "https://525b279a9462400e873e25ee7e2b3a0a.weavy.io";
let jour_week_time = get(queryString.parse(window.location.search), "jour_week_time") || null;
let is_iframe = get(queryString.parse(window.location.search), "is_iframe") || false;

const AUTH_DATA = { headers: { authorization: `Bearer ${jour_week_time}` } };

const App = () => {
  // const BACKEND_URL = "http://192.168.43.214:5000";
  const BACKEND_URL = "https://base30.onrender.com";

  const [weavyClient, setWeavyClient] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getToken = useCallback(async (refresh) => {
    let res = await axios.get(`${BACKEND_URL}/weavy/get-token`, AUTH_DATA).catch(err => console.log(err));
    return res.data.access_token
  }, []);

  useEffect(() => {
    if (user) {
      let client = new WeavyClient({ url: WEAVY_ENV_URL, tokenFactory: getToken });
      setWeavyClient(client);
    }
  }, [getToken, user]);

  useEffect(() => {
    async function fetchUser() {
      // get signed in user from server if available
      let res = await axios.get(`${BACKEND_URL}/me`, AUTH_DATA).catch(err => console.log(err));
      let user = res.data;

      setUser(user);
      setLoading(false);
    }
    fetchUser();
  }, []);

  const setWelcomeText = () => {
    document.querySelector(".wy-avatar-header").innerHTML = `
      <div class="flex flex-col items-center justify-center">
        <img class="wy-avatar" height="256" width="256" src="https://basexcorp.com/images/base30.png">

        <button
          id="btn_view_all_messages"
          class="font-thin text-base text-white bg-[#46a421] flex items-center mt-[5rem] px-6 py-3 shadow-sm rounded-md"
        >
          Voir tous les messages
        </button>
      </div>`;

    let btnViewAll = document.querySelector(`#btn_view_all_messages`);

    if (btnViewAll) {
      btnViewAll.addEventListener("click", e => {
        document.querySelector(".conv-page").classList.replace("w-full", "w-0");
        document.querySelector(".conv-list").classList.replace("w-0", "w-full");
      });
    }
  }

  return (
    <div className="App">
      {!user && <Loading />}

      {user &&
        <div className='chat-messenger-basex'>

          <div className="absolute top-0 left-0 right-0 bottom-0" >
            <Loading />
          </div>

          <WeavyProvider client={weavyClient}>
            <MessengerProvider>
              <div className="conv-container flex items-center jusfify-center relative bg-[#f0f4f9]">

                {!is_iframe &&
                  <div
                    className="fixed h-[3rem] top-0 left-[2.75rem] md:left-[2.75rem] z-[5000] flex items-center"
                  >
                    <a
                      href="https://basexcorp.com"
                      className="text-sm flex items-center font-thin text-white bg-[#46a421] px-2 md:px-3 py-1 shadow-sm rounded-md"
                    >
                      Acceuil
                    </a>
                  </div>
                }

                <div className="conv-list bg-white h-screen w-full overflow-x-hidden"
                  onClick={() => {
                    // 1️⃣ Show message
                    document.querySelector(".conv-page").classList.replace("w-0", "w-full");
                    document.querySelector(".conv-list").classList.replace("w-full", "w-0");
                    setWelcomeText();

                    // 1️⃣ Show Message (Or) 
                    // document.querySelectorAll(".wy-item .wy-item-body , .wy-item .wy-avatar-presence")
                    //   .forEach(convItem => {
                    //     convItem.addEventListener("click", e => {
                    //       document.querySelector(".conv-list").classList.replace("w-full", "w-0");
                    //       document.querySelector(".conv-page").classList.replace("w-0", "w-full");
                    //     })
                    //   });

                    // 2️⃣ Add event listener to back button
                    document.querySelector(`.wy-button.wy-button-icon > svg[data-icon="back"]`)
                      .addEventListener("click", e => {
                        document.querySelector(".conv-page").classList.replace("w-full", "w-0");
                        document.querySelector(".conv-list").classList.replace("w-0", "w-full");
                        setWelcomeText();
                      });
                  }}
                >
                  <ConversationList />
                </div>
                <div className="conv-page bg-white fixed top-0 left-0 right-0 h-[90vh] sm:h-screen w-0 overflow-hidden"
                  onClick={() => null /*...*/}
                >
                  <div className="bg-white h-full overflow-x-hidden">
                    <Conversation showBackButton />
                  </div>
                </div>
              </div>
            </MessengerProvider>
          </WeavyProvider>
        </div>
      }
    </div >
  )
}

export default App;
